.gallery {
  --columns-count: 3;
}

@media (--desktop) {
  .slide {
    --width: 460px;
  }
}

@media (--mobile-m) {
  .slide {
    --width: 350px;
  }
}

@media (--mobile) {
  .slide {
    --width: 310px;
  }
}

@media (--mobile-xxs) {
  .slide {
    --width: 260px;
  }
}
