.section {
  margin-top: var(--space-e);
}

.promptsWrapper {
  composes: main from 'containers';

  overflow: hidden;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 42px;
  padding: 0 var(--space-s);

  color: var(--color-black);

  background-color: transparent;
  border: 1px solid var(--color-medium-gray);
  border-radius: 45px;
  outline: 0;
  cursor: pointer;

  transition: all 0.2s;

  &:hover {
    background: var(--color-light-gray);
    background-blend-mode: multiply;
    border-color: var(--color-medium-gray);
  }

  &:focus-visible {
    border-color: var(--color-border-focus);
  }

  &:active {
    background: transparent;
    border-color: var(--color-black);
  }
}

.promptName {
  font-weight: 400;
  font-size: var(--size-s);
  font-family: var(--font-main);
  line-height: 1.5;
  white-space: nowrap;
}

.showAll {
  align-content: center;

  color: #989898;

  cursor: pointer;
}

.chevron {
  width: 10px;
  margin-left: var(--space-xxs);

  transition: 0.5s;

  &.active {
    transform: rotate(180deg);
  }
}

@media (--desktop-m) {
  .section {
    margin-top: var(--space-xb);
  }

  .list {
    gap: var(--space-xs);
  }
}

@media (--mobile-m) {
  .section {
    margin-top: var(--space-b);
  }
}
