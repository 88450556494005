.section {
  margin-top: var(--space-e);
}

.review {
  height: 535.25px;
}

@media (--desktop-m) {
  .section {
    margin-top: var(--space-xb);
  }
}

@media (--desktop) {
  .gallery {
    --columns-count: 2;
  }
}

@media (--mobile-m) {
  .slide {
    --width: 300px;
  }

  .section {
    margin-top: var(--space-b);
  }

  .review {
    height: 425.5px;
  }
}

@media (--mobile-xxs) {
  .slide {
    --width: 275px;
  }
}
