.banner {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  height: 300px;
  padding: var(--space-l) var(--space-xxb);

  color: var(--color-black);

  &.inversed {
    color: var(--color-white);
  }

  &.withImage {
    height: 400px;
  }
}

.image {
  position: absolute;
  inset: 0;
}

.container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  text-align: center;
}

.period {
  margin-bottom: var(--space-s);

  font-weight: 500;
  font-size: var(--size-l);
  line-height: 1.5;
}

.title {
  margin-top: auto;
  margin-bottom: var(--space-s);

  font-weight: 500;
  font-size: var(--size-xb);
  font-family: var(--font-hero), sans-serif;
  line-height: 1.5;
}

.subtitle {
  display: inline;

  font-size: var(--size-xl);
  line-height: 1.5;
}

.condition {
  font-size: var(--size-xs);
  line-height: 1.5;

  opacity: 0.7;
}

:is(.period, .title, .subtitle):last-child {
  margin-bottom: auto;
}

.condition:last-child {
  margin-top: auto;
}

.promocodeWrapper {
  z-index: 100;

  display: inline;
}

.marginRight {
  margin-right: var(--size-xs);
}

.marginLeft {
  margin-left: var(--size-xs);
}

.promocode {
  display: inline-block;

  font-size: 25px;
  > div > div {
    padding: 5px 10px !important;

    border-radius: 35px !important;
  }
}

@media (--desktop-m) {
  .banner {
    height: 250px;
    padding: var(--space-l) var(--space-xxl);

    &.withImage {
      height: 350px;
    }
  }

  .period {
    font-size: var(--size-m);
  }

  .title {
    font-size: var(--size-b);
  }

  .subtitle {
    font-size: var(--size-l);
  }
}

@media (--desktop) {
  .banner {
    height: 200px;
    padding: var(--space-n) var(--space-xxl);

    &.withImage {
      height: 285px;
    }
  }

  .period {
    font-size: var(--size-n);
  }

  .title {
    font-size: var(--size-xxl);
  }
}

@media (--mobile-m) {
  .banner {
    height: 185px;
    padding: var(--space-n) var(--space-s);

    &.withImage {
      height: 245px;
    }
  }

  .title {
    font-size: var(--size-xl);
  }

  .subtitle {
    font-size: var(--size-m);
  }
}

@media (--mobile) {
  .banner {
    height: 240px;
    padding: var(--space-n) var(--space-s);
  }

  .container {
    align-items: flex-start;

    text-align: left;
  }

  .promocode {
    font-size: 18px;
  }

  .titles {
    justify-content: flex-start;
  }

  .container :first-child {
    margin-top: 0;
  }
}

/* + css modifiers sent by the backend */
@media (--mobile) {
  .mobileReducedTitle .title {
    font-size: 22.5px;
  }
}
/* - css modifiers sent by the backend */
